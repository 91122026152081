import { Box, Button, CircularProgress, Tab, Tabs, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { debounce } from "lodash";
import { ARROW_UP_RIGHT_WHITE_ICON } from "../../../../constants";
import TableController from "../../../../components/Tables/TableController";
import { StartupTableColumns } from "../../../Partner/Dashboard/Admin/StartupTableColumns";
import SortComponent from "../../../../components/Tables/SortComponent";
import { AppliedStartupColumns, AppliedStartupSortOptions, ProspectsColumns } from "./AppliedStartupColumns";
import services from "../../../../services";
import { toastContainer } from "../../../../utils/toast";
import messages from "../../../../constants/messages";
import MessageHandlerModal from "../../../../common/modal/MessageHandler";
import SearchComponent from "../../../../components/Tables/SearchComponent";
import { useDispatch } from "react-redux";
import { setSlidingProfileData } from "../../../../modules/actions/slidingProfileActions";
import { Can } from "../../../../services/abilities/context";

const AppliedStartups = ({ eventList, selectedSlide }) => {
	const APPLIED_STARTUPS = [
		{ id: 0, title: "Applied Startups" },
		{ id: 1, title: "Prospects" },
	];
	const [currentTab, setCurrentTab] = useState(0);
	const [sortCriteriaStartups, setSortCriteriaStartups] = useState({ colName: "", direction: "" });
	const [selectedRows, setSelectedRows] = useState([]);
	const [inviteLoader, setInviteLoader] = useState(false);
	const [inviteConfirmPopup, setInviteConfirmPopup] = useState(false);
	const [tableKey, setTableKey] = useState(0);
	const [searchText, setSearchText] = useState("");
	const dispatch = useDispatch();

	const handleRowSelection = (selectedIds) => {
		setSelectedRows(selectedIds);
	};
	const handleChange = (event, newValue) => {
		setCurrentTab(newValue);
	};
	const handleSortChange = (newSortCriteria) => {
		setSortCriteriaStartups(newSortCriteria);
		setTableKey((prevKey) => prevKey + 1);
	};

	const handleSendEventInvite = async () => {
		try {
			const payload = {
				eventUuid: eventList[selectedSlide]?.uuid,
				companyIds: selectedRows,
			};
			setInviteLoader(true);
			const response = await services.sendEventInvitationApi(payload);
			if (response) {
				setInviteLoader(false);
				toastContainer(messages.EVENT_INVITATION_SENT);
				setInviteConfirmPopup(false);
			}
		} catch (error) {
			setInviteLoader(false);
			setInviteConfirmPopup(false);

			console.error("Error in sent event invite:", error);
		}
	};

	const getPitchCompanies = async (pageNo, perPage, searchText = "") => {
		try {
			const isApplied = currentTab === 0;
			const eventUuid = eventList[selectedSlide]?.uuid;
			let page = pageNo + 1;
			const response = await services.getPitchCompaniesApi({
				page,
				perPage,
				isApplied,
				eventUuid,
				filter: sortCriteriaStartups?.colName,
				sort: sortCriteriaStartups?.direction,
				searchText,
			});
			if (response) {
				const { totalElements, models } = response?.data?.data;

				if (!models) {
					return {
						data: {
							totalElements: totalElements,
							models: [],
						},
					};
				}
				const newModels = models.map((model) => ({
					...model,
					name: {
						name: model.companyName,
						img: model.logoUrl,
					},
					location: model?.city && model?.state ? `${model?.city}, ${model?.state}` : "",
					industry: model?.industry && JSON.parse(model?.industry),
				}));
				return {
					data: {
						totalElements: totalElements,
						models: newModels,
					},
				};
			}
		} catch (error) {
			console.error("Error fetching pitch companies:", error);
		}
	};

	const searchPitchCompanies = debounce((e) => {
		setSearchText(e.target.value);
		setTableKey((prevKey) => prevKey + 1);
	}, 600);
	const openStartupProfile = (id) => {
		dispatch(
			setSlidingProfileData({
				open: true,
				profileType: "STARTUP_PROFILE",
				profileId: id,
			})
		);
	};
	return (
		<>
			<Box display={"flex"} justifyContent={"space-between"}>
				{" "}
				<Tabs
					variant="scrollable"
					scrollButtons="auto"
					allowScrollButtonsMobile
					value={currentTab}
					onChange={handleChange}
					sx={{
						alignItems: "stretch",
						"& .MuiTabs-flexContainer": {
							gap: 5,
						},
						"& .MuiTabs-indicator": {
							backgroundColor: (theme) => theme.palette.primary.main,
							borderRadius: "5px",
							height: "4px",
						},
						"& .MuiTabs-scrollButtons": {
							color: (theme) => theme.palette.primary.main,
							"& > svg": {
								height: "30px",
								width: "30px",
							},
						},
					}}
				>
					{APPLIED_STARTUPS.map((item, index) => {
						const tabItem = (
							<Tab
								selected={currentTab === item.id}
								onClick={(event) => {
									handleChange(event, index);
								}}
								disableRipple
								key={item.id}
								sx={{
									textTransform: "unset",
									textAlign: "left",
									p: 0,
									pb: 1,
									whiteSpace: "nowrap",
									maxWidth: "unset",
									opacity: ".6",
									filter: "grayscale(100%)",
									"&.Mui-selected": {
										opacity: 1,
										filter: "grayscale(0%)",
									},
								}}
								label={
									<Typography
										variant="Text/xs/Semibold"
										sx={{
											color: (theme) => theme.palette.primary.main,
										}}
									>
										{item.title}
									</Typography>
								}
							/>
						);
						return <Fragment key={index}>{tabItem}</Fragment>;
					})}
				</Tabs>
				{currentTab === 1 && (
					<Can I="manage" a="EventApplicants">
						<Button
							sx={{ alignItems: "baseline" }}
							startIcon={
								<Box
									component={"img"}
									src={ARROW_UP_RIGHT_WHITE_ICON}
									height={"10px"}
									width={"10px"}
									sx={{
										height: "14px !important",
										width: "14px !important",
									}}
									alt="arrow"
								/>
							}
							disabled={selectedRows?.length < 1}
							onClick={() => setInviteConfirmPopup(true)}
							variant="DS1"
						>
							Send Invite to Selected Prospects
						</Button>
					</Can>
				)}
			</Box>

			{currentTab === 0 && (
				<Box>
					<TableController
						key={`applied_startups_${tableKey}`}
						autoHeight={false}
						requestData={(currentPage, pageSize, searchText) => getPitchCompanies(currentPage, pageSize, searchText)}
						columnDefs={AppliedStartupColumns()}
						title="Applied Startups"
						showCount
						countLabel={{ singular: "Applied Startup", plural: "Applied Startups" }}
						contextKey={selectedSlide}
						icon={
							<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
								<path
									d="M20 28V20.8C20 20.0533 20 19.6799 19.8547 19.3947C19.7268 19.1438 19.5229 18.9398 19.272 18.812C18.9868 18.6667 18.6134 18.6667 17.8667 18.6667H14.1333C13.3866 18.6667 13.0132 18.6667 12.728 18.812C12.4771 18.9398 12.2732 19.1438 12.1453 19.3947C12 19.6799 12 20.0533 12 20.8V28M25.3333 28V8.26667C25.3333 6.77319 25.3333 6.02646 25.0427 5.45603C24.787 4.95426 24.3791 4.54631 23.8773 4.29065C23.3069 4 22.5601 4 21.0667 4H10.9333C9.43986 4 8.69312 4 8.12269 4.29065C7.62093 4.54631 7.21298 4.95426 6.95732 5.45603C6.66667 6.02646 6.66667 6.77319 6.66667 8.26667V28M28 28H4M12.6667 10.6667H12.68M19.3333 10.6667H19.3467M13.3333 10.6667C13.3333 11.0349 13.0349 11.3333 12.6667 11.3333C12.2985 11.3333 12 11.0349 12 10.6667C12 10.2985 12.2985 10 12.6667 10C13.0349 10 13.3333 10.2985 13.3333 10.6667ZM20 10.6667C20 11.0349 19.7015 11.3333 19.3333 11.3333C18.9651 11.3333 18.6667 11.0349 18.6667 10.6667C18.6667 10.2985 18.9651 10 19.3333 10C19.7015 10 20 10.2985 20 10.6667Z"
									stroke={(theme) => theme?.palette?.primary?.main}
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						}
						noResultsMessage={
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									gap: "16px",
									alignSelf: "stretch",
									flex: 1,
									justifyContent: "center",
								}}
							>
								<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
									<path
										d="M28 28L23.3335 23.3333M26.6667 15.3333C26.6667 21.5926 21.5926 26.6667 15.3333 26.6667C9.07411 26.6667 4 21.5926 4 15.3333C4 9.07411 9.07411 4 15.3333 4C21.5926 4 26.6667 9.07411 26.6667 15.3333Z"
										stroke={(theme) => theme?.palette?.primary?.main}
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
								<Typography variant="Text/sm/Semibold">No applied startups found</Typography>
							</Box>
						}
						sortComponent={
							<SortComponent
								sortOptions={AppliedStartupSortOptions}
								onSortChange={(obj) => handleSortChange(obj)}
								currentSort={sortCriteriaStartups}
							/>
						}
						onRowClick={(res) => openStartupProfile(res?.id)}
						searchText={searchText}
						searchComponent={<SearchComponent searchText={searchText} handleChange={searchPitchCompanies} />}
						dataGridProps={{ disableColumnMenu: true }}
					/>
				</Box>
			)}
			{currentTab === 1 && (
				<Box>
					<TableController
						key={`prospects_${tableKey}`}
						autoHeight={false}
						requestData={(currentPage, pageSize, searchText) => getPitchCompanies(currentPage, pageSize, searchText)}
						columnDefs={ProspectsColumns()}
						title="Prospects"
						showCount
						countLabel={{ singular: "Prospect", plural: "Prospects" }}
						icon={
							<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
								<path
									d="M20 28V20.8C20 20.0533 20 19.6799 19.8547 19.3947C19.7268 19.1438 19.5229 18.9398 19.272 18.812C18.9868 18.6667 18.6134 18.6667 17.8667 18.6667H14.1333C13.3866 18.6667 13.0132 18.6667 12.728 18.812C12.4771 18.9398 12.2732 19.1438 12.1453 19.3947C12 19.6799 12 20.0533 12 20.8V28M25.3333 28V8.26667C25.3333 6.77319 25.3333 6.02646 25.0427 5.45603C24.787 4.95426 24.3791 4.54631 23.8773 4.29065C23.3069 4 22.5601 4 21.0667 4H10.9333C9.43986 4 8.69312 4 8.12269 4.29065C7.62093 4.54631 7.21298 4.95426 6.95732 5.45603C6.66667 6.02646 6.66667 6.77319 6.66667 8.26667V28M28 28H4M12.6667 10.6667H12.68M19.3333 10.6667H19.3467M13.3333 10.6667C13.3333 11.0349 13.0349 11.3333 12.6667 11.3333C12.2985 11.3333 12 11.0349 12 10.6667C12 10.2985 12.2985 10 12.6667 10C13.0349 10 13.3333 10.2985 13.3333 10.6667ZM20 10.6667C20 11.0349 19.7015 11.3333 19.3333 11.3333C18.9651 11.3333 18.6667 11.0349 18.6667 10.6667C18.6667 10.2985 18.9651 10 19.3333 10C19.7015 10 20 10.2985 20 10.6667Z"
									stroke={(theme) => theme?.palette?.primary?.main}
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						}
						noResultsMessage={
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									gap: "16px",
									alignSelf: "stretch",
									flex: 1,
									justifyContent: "center",
								}}
							>
								<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
									<path
										d="M28 28L23.3335 23.3333M26.6667 15.3333C26.6667 21.5926 21.5926 26.6667 15.3333 26.6667C9.07411 26.6667 4 21.5926 4 15.3333C4 9.07411 9.07411 4 15.3333 4C21.5926 4 26.6667 9.07411 26.6667 15.3333Z"
										stroke={(theme) => theme?.palette?.primary?.main}
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
								<Typography variant="Text/sm/Semibold">No prospects found</Typography>
							</Box>
						}
						sortComponent={
							<SortComponent
								sortOptions={AppliedStartupSortOptions}
								onSortChange={(obj) => handleSortChange(obj)}
								currentSort={sortCriteriaStartups}
							/>
						}
						searchText={searchText}
						searchComponent={<SearchComponent searchText={searchText} handleChange={searchPitchCompanies} />}
						dataGridProps={{
							disableColumnMenu: true,
							checkboxSelection: true,
							onRowSelectionModelChange: handleRowSelection,
							selectionModel: selectedRows, // Pass selectedRows to manage selection
						}}
						onRowClick={(res) => openStartupProfile(res?.id)}
					/>
				</Box>
			)}
			<MessageHandlerModal
				isOpen={inviteConfirmPopup}
				onCancel={() => setInviteConfirmPopup(false)}
				onOk={() => {
					handleSendEventInvite();
				}}
				okText="Yes"
				cancelText={"Cancel"}
				heading={`Send Invites to Selected Prospects?`}
				text={`Are you sure you want to send invites out to selected prospects? Recipients of the invite will receive an email about the event and it’s details.`}
				messageType="primary"
			/>
		</>
	);
};

export default AppliedStartups;
