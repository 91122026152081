import { USER_TYPES } from "../../utils/userTypes";

const permissionMapping = {
	"manage-startups": { action: "manage", subject: "AcceleratorStartups" },
	"manage-founders": { action: "manage", subject: "AcceleratorFounders" },
	"manage-mentors": { action: "manage", subject: "AcceleratorMentors" },
	"manage-investors": { action: "manage", subject: "AcceleratorInvestors" },
	"manage-admins": { action: "manage", subject: "AcceleratorAdmins" },
	"manage-roles": { action: "manage", subject: "AcceleratorRoles" },
	"manage-groups": { action: "manage", subject: "AcceleratorGroups" },
	"invite-users": { action: "manage", subject: "AcceleratorUsers" },
	"track-startups": { action: "manage", subject: "AcceleratorTrackedStartups" },
	"configure-accelerator": { action: "manage", subject: "AcceleratorConfiguration" },
	"view-founders": { action: "view", subject: "AcceleratorFounders" },
	"view-all-users": { action: "view", subject: "AcceleratorUsers" },
	"view-startups": { action: "view", subject: "AcceleratorStartups" },
	"view-admins": { action: "view", subject: "AcceleratorAdmins" },
	"view-investors": { action: "view", subject: "AcceleratorInvestors" },
	"view-mentors": { action: "view", subject: "AcceleratorMentors" },
	"view-groups": { action: "view", subject: "AcceleratorGroups" },
	"view-roles": { action: "view", subject: "AcceleratorRoles" },
};

export function defineAcceleratorAdminAbilities(user, can, cannot) {
	const hasManageAllUsers = user?.permissions?.some((permission) => permission.name === "manage-all-users");
	const hasViewAllUsers = user?.permissions?.some((permission) => permission.name === "view-all-users");

	// Loop over user permissions to define abilities
	user?.permissions?.forEach((permission) => {
		const mapping = permissionMapping[permission?.name];
		if (mapping) {
			can(mapping?.action, mapping?.subject);

			// If the permission is for managing, also grant viewing rights
			if (mapping.action === "manage") {
				can("view", mapping?.subject);
			}
		}
	});

	// If user has the attribute: "manage-all-users" ensure the user has access to all user types
	if (hasManageAllUsers) {
		can("manage", "AcceleratorFounders");
		can("view", "AcceleratorFounders");

		can("manage", "AcceleratorMentors");
		can("view", "AcceleratorMentors");

		can("manage", "AcceleratorInvestors");
		can("view", "AcceleratorInvestors");

		can("manage", "AcceleratorAdmins");
		can("view", "AcceleratorAdmins");
	}

	// If user has the attribute: "view-all-users" ensure the user has view access to all user types
	if (hasViewAllUsers) {
		can("view", "AcceleratorFounders");
		can("view", "AcceleratorMentors");
		can("view", "AcceleratorInvestors");
		can("view", "AcceleratorAdmins");
	}

	// Check if the user's permissions allow any of the relevant subjects for Accelerator Dashboard access
	const hasSomeAcceleratorAccess = user?.permissions?.some((permission) => {
		// Check if the user's permission name matches a key in permissionMapping
		return Object.keys(permissionMapping).some((key) => key === permission?.name);
	});

	// If the user has any accelerator dashboard permissions; allow them to view the component.
	if (hasSomeAcceleratorAccess) {
		can("view", "AcceleratorDashboard");
	}
}
